import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { unByKey } from 'ol/Observable';
// We need to use @rjsf/validator-ajv6 instead of @rjsf/validator-ajv8 because ajv8 will not successfully
// validate the schema for referenced fields (e.g. PersonField) from Django Restframwork ("nullable objects must
// have the type attribute")
import { customizeValidator } from '@rjsf/validator-ajv6';
import Form from '@rjsf/mui';
import DeleteDialog from '../DeleteDialog';
import ObjectFieldTemplate from '../ObjectFieldTemplate';
import ArrayFieldTemplate from '../ArrayFieldTemplate';
import SaveResultMessage from '../SaveResultMessage';
import CancelDialog from '../CancelDialog';
import PreviewWarnDialog from '../PreviewWarnDialog';
import FormHeader from '../FormHeader';
import { isNewFeature } from '../../utils/featureUtils';
import {
  setDialogVisible,
  setSelectedFeature,
  saveFormData,
  bulkSaveFormData,
  setFeatures,
  setRoutingFeatures,
  setIsFormUnsaved,
  getFeatures,
  setIsPreviewActive,
  setPreviewEnableValue,
  setIsCancelDialogVisible,
  setFormData,
  setCancelDialogOnAction,
} from '../../model/app/actions';
import messtischWidgets from '../widgets';
import customFormats from '../../customFormats';
import usePrevious from '../../utils/usePrevious';

let geomChangeTimeout = null;

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.199)',
    '& button[type=submit]': {
      display: 'none', // we use our own button
    },
  },
  form: {
    overflow: 'hidden auto',
    flex: 1,
    '& .MuiGrid-item': {
      marginBottom: 0,
    },
  },
  footer: {
    padding: 7,
    borderTop: '1px solid #eee',
    zIndex: 1400,
  },
  footerButton: {
    '&.MuiButton-root': {
      border: '1px solid #eee',
      margin: 5,
    },
  },
});

const validator = customizeValidator({ customFormats });

function shouldSuppressError(formDataa, schema, error) {
  // Based on the masterpiece: https://github.com/rjsf-team/react-jsonschema-form/issues/2150
  if (
    error.name === 'type' &&
    (error.params.type === 'object' || error.params.type === 'integer')
  ) {
    const property = error.property.split('.')[1];
    return (
      schema &&
      schema.properties[property] &&
      schema.properties[property].nullable &&
      !formDataa[property]
    );
  }
  if (error.name === 'required') {
    const parentFieldId = error.property
      .split('.')[1]
      .replace(/\[(\d*[1-9]+\d*|0)\]/, '');
    const parentFieldValue = formDataa[parentFieldId];
    return (
      parentFieldValue &&
      schema?.properties[parentFieldId]?.nullable &&
      JSON.stringify(parentFieldValue) === '{}'
    );
  }
  if (error.name === 'format') {
    const property = error.property.split('.')[1];
    return (
      schema &&
      schema.properties[property] &&
      !schema.required[property] &&
      !formDataa[property]
    );
  }
  if (error.name === 'type') {
    const property = error.property
      .split('.')[1]
      .replace(/\[(\d*[1-9]+\d*|0)\]/, '');
    const isArrayType = schema.properties[property]?.type === 'array';
    const itemIndex = error.property.split('.')[2];
    return isArrayType && formDataa[property][itemIndex] === undefined;
  }
  return false;
}

let formChangeDebounceTimeout;

const FormWrapper = () => {
  const classes = useStyles();
  const [refForm, setRefForm] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // redux
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const fields = useSelector((state) => state.app.fields);
  const map = useSelector((state) => state.map.map);
  const topic = useSelector((state) => state.app.topic);
  const schema = useSelector((state) => state.app.schema);
  const uiSchema = useSelector((state) => state.app.uiSchema);
  const features = useSelector((state) => state.app.features);
  const routingFeatures = useSelector((state) => state.app.routingFeatures);
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const bulkFeatures = useSelector((state) => state.app.bulkFeatures);
  const filters = useSelector((state) => state.app.filters);
  const isFormUnsaved = useSelector((state) => state.app.isFormUnsaved);
  const isPreviewActive = useSelector((state) => state.app.isPreviewActive);
  const previewEnabled = useSelector((state) => state.app.previewEnabled);
  const isPreviewLoading = useSelector((state) => state.app.isPreviewLoading);
  const formData = useSelector((state) => state.app.formData);
  const cancelDialogOnAction = useSelector(
    (state) => state.app.cancelDialogOnAction,
  );
  const isCancelDialogVisible = useSelector(
    (state) => state.app.isCancelDialogVisible,
  );
  const prevSelectedFeature = useSelector(
    (state) => state.app.prevSelectedFeature,
  );
  const prevFormData = usePrevious(formData);

  // state
  const [extraErrors, setExtraErrors] = useState();
  const [hasFormErrors, setHasFormErrors] = useState();
  const [saveSuccess, setSaveSuccess] = useState();
  const [initialFeature, setInitialFeature] = useState();
  const [saving, setSaving] = useState(false);

  const getSavedFeatures = useCallback(() => {
    return features.filter((feature) => !isNewFeature(feature));
  }, [features]);

  // Define if the selected feature is the same as the one in the form.
  const hasSelectedFeatureChanged = useMemo(() => {
    return (
      (!initialFeature && selectedFeature) ||
      (selectedFeature &&
        selectedFeature.get('id') !== initialFeature.get('id'))
    );
  }, [selectedFeature, initialFeature]);

  const cancel = useCallback(() => {
    if (hasSelectedFeatureChanged) {
      dispatch(setSelectedFeature(prevSelectedFeature));
    }
    dispatch(setIsCancelDialogVisible(false));
  }, [hasSelectedFeatureChanged, dispatch, prevSelectedFeature]);

  useEffect(() => {
    if (selectedFeature) {
      setExtraErrors(null);
      setSaveSuccess();
    }
  }, [selectedFeature, dispatch]);

  // When the selected feature changes.
  useEffect(() => {
    if (hasSelectedFeatureChanged && isFormUnsaved && !bulkFeatures.length) {
      dispatch(setIsCancelDialogVisible(true));
      return;
    }
    if (!selectedFeature) {
      return;
    }

    // Update the initial feature only when necessary
    if (hasSelectedFeatureChanged) {
      setSaveSuccess();
      setInitialFeature(selectedFeature.clone());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedFeatureChanged, selectedFeature, bulkFeatures, isFormUnsaved]);

  useEffect(() => {
    if (!initialFeature || !selectedFeature) {
      return;
    }

    // Update form data when switching between new stations from vTiles
    if (topic.onVtileStationClick instanceof Function) {
      if (
        isNewFeature(initialFeature) &&
        isNewFeature(selectedFeature) &&
        initialFeature !== selectedFeature
      ) {
        dispatch(setFormData({ ...selectedFeature.getProperties() }));
      }
    }
  }, [selectedFeature, topic, dispatch, initialFeature]);

  useEffect(() => {
    dispatch(setIsFormUnsaved(false));

    if (!initialFeature) {
      return;
    }

    if (backendApiUrl && topic.fetchFeatureOnFormOpen) {
      const featId = initialFeature.get('id');
      fetch(`${backendApiUrl}/${topic.key}/form/${featId}/`, {
        credentials: 'include',
      })
        .then((data) => data.json())
        .then((data) => dispatch(setFormData({ ...data, id: featId })))
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          dispatch(setSelectedFeature());
        });
    } else {
      dispatch(setFormData({ ...initialFeature.getProperties() }));
    }
  }, [initialFeature, topic, dispatch, backendApiUrl]);

  useEffect(() => {
    if (isPreviewActive && isFormUnsaved) {
      dispatch(setIsPreviewActive(false));
      dispatch(setIsCancelDialogVisible(true));
    }
  }, [dispatch, isPreviewActive, isFormUnsaved]);

  // When the geometry of the selected feature changes.
  useEffect(() => {
    const featureChangeKey =
      selectedFeature &&
      selectedFeature.getGeometry() &&
      selectedFeature.getGeometry().on('change', () => {
        window.clearTimeout(geomChangeTimeout);
        const geom1 = selectedFeature.getGeometry().getCoordinates();
        const geom2 = initialFeature.getGeometry().getCoordinates();
        geomChangeTimeout = window.setTimeout(() => {
          dispatch(setFormData({ ...selectedFeature.getProperties() }));
          if (geom1 !== geom2) {
            dispatch(setIsFormUnsaved(true));
          }
        }, 500);
      });

    return () => {
      window.clearTimeout(geomChangeTimeout);
      unByKey(featureChangeKey);
    };
  }, [initialFeature, selectedFeature, dispatch]);

  const transformErrors = useCallback(
    (errors, uiSchemaa, inputData) => {
      const formDataToTest = inputData || formData;
      // Ignores errors if formData doesn't refers to the selectedFeature.
      // it happens when we select features one after each other.
      if (formDataToTest.id !== selectedFeature.get('id')) {
        return [];
      }
      const def = errors.filter(
        (error) => !shouldSuppressError(formDataToTest, schema, error),
      );

      // Remove pattern message because they are not user friendly.
      let cleanDef = def.filter((error) => {
        return error.name !== 'pattern';
      });

      // Display better message for uri format.
      cleanDef = cleanDef.map((error) => {
        if (/format/.test(error.name) && /uri/.test(error.params.format)) {
          return {
            ...error,
            message: 'Muss diesem Format entsprechen: https://www.example.org.',
          };
        }
        if (
          /required/.test(error.name) ||
          (/enum/.test(error.name) && 'allowedValues' in error.params)
        ) {
          return {
            ...error,
            message: 'Dieses Feld ist obligatorisch.',
          };
        }
        return error;
      });
      return cleanDef;
    },
    [formData, schema, selectedFeature],
  );

  const submitBtn = useMemo(() => {
    // Dear reviewer. I know this seems like a pretty ugly hack... and it is! But rjsf can't seem to
    // get Firefox to submit the form from an external button without reloading the whole app.
    // Please see https://github.com/rjsf-team/react-jsonschema-form/issues/500
    // As a workaround we make our external button programmatically click the hidden submit button.
    // We select the last submit button from the node list since we might have multiple forms with multiple
    // submit buttons (see RoutingField component)
    return (
      refForm && [...document.querySelectorAll('button[type=submit]')].pop()
    );
  }, [refForm]);

  if (!formData) {
    return null;
  }

  // The property geometry (an ol.geom.Point) is transformed into a simple js object by the Form,
  // so to avoid errors we remove it from FormData object.
  delete formData.geometry;

  /**
   * Called on form save
   */
  const onSubmit = (data, closeOnSave = false) => {
    setSaveSuccess();
    const url = `${backendApiUrl}/${topic.key}/form/`;
    const promise =
      bulkFeatures.length > 1
        ? dispatch(bulkSaveFormData(url, data, bulkFeatures))
        : dispatch(saveFormData(url, data));
    setSaving(true);

    promise.then((response) => {
      const { type } = response;
      const isSuccess = type !== 'SET_ERROR';
      setSaveSuccess(isSuccess);

      if (isSuccess) {
        setExtraErrors();
        setSaving(false);
        dispatch(setIsFormUnsaved(false));

        if (closeOnSave) {
          dispatch(setSelectedFeature());
        } else {
          setInitialFeature(selectedFeature.clone());
        }

        if (isPreviewLoading) {
          dispatch(setDialogVisible(PreviewWarnDialog.NAME));
        }
        if (previewEnabled) {
          dispatch(setIsPreviewActive(false));
          dispatch(setPreviewEnableValue());
        }
      } else {
        setExtraErrors(response.data && response.data.extraErrors);
        setSaving(false);
      }

      if (topic.syncFeaturesOnSave) {
        dispatch(getFeatures(topic, filters, selectedFeature));
      }
    });
  };

  return (
    <div className={classes.root}>
      <FormHeader
        onClose={() => {
          if (isFormUnsaved) {
            dispatch(setIsCancelDialogVisible(true));
          } else {
            dispatch(setSelectedFeature());
          }
        }}
      />
      <Form
        validator={validator}
        liveValidate
        noHtml5Validate
        noValidate={!!bulkFeatures.length}
        id="form"
        ref={(el) => setRefForm(el)}
        enctype="multipart/form-data"
        autoComplete="off"
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        fields={fields}
        widgets={messtischWidgets}
        templates={{
          ObjectFieldTemplate,
          ArrayFieldTemplate,
          ErrorListTemplate: () => null,
        }}
        extraErrors={extraErrors}
        transformErrors={transformErrors}
        formContext={{
          selectedFeature,
          backendApiUrl,
          map,
        }}
        className={classes.form}
        onSubmit={(data) => onSubmit(data.formData)}
        onChange={(data) => {
          const updateData = (inputData) => {
            setSaveSuccess();
            // We execute transformErrors with the real modified formData
            // fix bug when you try to clear a PersonField input
            let errs = transformErrors(
              inputData.errors,
              uiSchema,
              inputData.formData,
            );

            // Filter out backend errors
            // backend errors have only a stack property
            errs = errs.filter((err) => {
              return err.name;
            });
            setExtraErrors();
            setHasFormErrors(errs.length > 0);

            // Update the selected feature with the current form data.
            selectedFeature.setProperties(inputData.formData);

            if (routingFeatures?.length) {
              // Update the routing features.
              dispatch(
                setRoutingFeatures(
                  routingFeatures.map((feat) => {
                    if (feat.get('id') === inputData.formData.id) {
                      const {
                        graph,
                        maxResolution,
                        minResolution,
                      } = feat.getProperties();
                      feat.setProperties({
                        ...inputData.formData,
                        graph,
                        maxResolution,
                        minResolution,
                      });
                    }
                    return feat;
                  }),
                ),
              );
            }

            const hasSwitchedToBulkMode =
              !prevFormData?.selectedFeaturesIds &&
              !!formData?.selectedFeaturesIds;

            // Update formData and errors messages.
            dispatch(setFormData({ ...inputData.formData }));
            dispatch(setIsFormUnsaved(!hasSwitchedToBulkMode));
          };

          if (routingFeatures?.length) {
            updateData(data);
            return;
          }
          // We use a debounce here to prevent the listview from reloading excessively and slowing down the form
          clearTimeout(formChangeDebounceTimeout);
          formChangeDebounceTimeout = setTimeout(() => updateData(data), 200);
        }}
      />
      <Container className={classes.footer}>
        <Button
          className={classes.footerButton}
          onClick={() => submitBtn.click()}
          disabled={hasFormErrors || !isFormUnsaved || saving}
        >
          {t('Anwenden')}
        </Button>
        <Button
          className={classes.footerButton}
          onClick={() => {
            // Remove new feature if it wasn't saved
            if (isNewFeature(selectedFeature)) {
              const savedRoutingFeatures = routingFeatures.filter(
                (feature) => !isNewFeature(feature),
              );
              dispatch(setFeatures([...getSavedFeatures()]));
              dispatch(setRoutingFeatures(savedRoutingFeatures));
            } else {
              // We apply the properties before changes.
              selectedFeature.setProperties({
                ...initialFeature.getProperties(),
              });

              if (initialFeature.getGeometry()) {
                selectedFeature.setGeometry(
                  initialFeature.getGeometry().clone(),
                );
              }
            }
            // Unselect feature and close the form
            dispatch(setSelectedFeature());
            if (topic.syncFeaturesOnSave) {
              dispatch(getFeatures(topic, filters));
            }
          }}
          disabled={!isFormUnsaved && !isNewFeature(selectedFeature)}
        >
          {t('Abbrechen')}
        </Button>
        {topic.copyFeatureProperties && (
          <Button
            className={classes.footerButton}
            disabled={hasFormErrors}
            onClick={() => {
              const copy = topic.copyFeatureProperties(selectedFeature);
              dispatch(
                saveFormData(
                  `${backendApiUrl}/${topic.key}/form`,
                  copy.getProperties(),
                ),
              ).then(({ type, data }) => {
                if (type !== 'SET_ERROR' && data) {
                  dispatch(setSelectedFeature(data));
                  dispatch(setFormData({ ...data.getProperties() }));
                }
                setSaveSuccess(type !== 'SET_ERROR');
              });
            }}
          >
            {t('Kopieren')}
          </Button>
        )}
        {topic?.oleConfig?.canDeleteGeometries !== false && (
          <Button
            className={classes.footerButton}
            disabled={isNewFeature(selectedFeature)}
            onClick={() => {
              dispatch(setDialogVisible(DeleteDialog.NAME));
            }}
          >
            {t('Löschen')}
          </Button>
        )}
        <SaveResultMessage isSaveSuccess={saveSuccess} />
      </Container>
      {isCancelDialogVisible && (
        <CancelDialog
          hasFormErrors={hasFormErrors}
          onClose={cancel}
          onSave={() => {
            onSubmit(
              (selectedFeature || prevSelectedFeature).getProperties(),
              !hasSelectedFeatureChanged,
            );
            dispatch(setIsCancelDialogVisible(false));
            if (typeof cancelDialogOnAction === 'function') {
              cancelDialogOnAction(selectedFeature, topic);
              dispatch(setCancelDialogOnAction(null));
            }
          }}
          onNoSave={() => {
            const featureToReset = hasSelectedFeatureChanged
              ? prevSelectedFeature
              : selectedFeature;

            // Remove new feature if it wasn't saved
            if (isNewFeature(featureToReset)) {
              dispatch(setFeatures([...getSavedFeatures()]));
            } else {
              // Reset feature
              featureToReset.setProperties({
                ...initialFeature.getProperties(),
              });

              if (initialFeature.getGeometry()) {
                featureToReset.setGeometry(
                  initialFeature.getGeometry().clone(),
                );
              }
            }

            if (hasSelectedFeatureChanged) {
              // Select the new feature
              setInitialFeature(selectedFeature.clone());
              dispatch(setIsCancelDialogVisible(false));
            } else {
              // Unselect feature and close the form
              dispatch(setSelectedFeature());
            }

            if (typeof cancelDialogOnAction === 'function') {
              cancelDialogOnAction(selectedFeature, topic);
              dispatch(setCancelDialogOnAction(null));
            }
          }}
          onCancel={cancel}
        />
      )}
    </div>
  );
};

export default React.memo(FormWrapper);
