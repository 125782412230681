import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, CircularProgress, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { getFeatures, setDialogVisible } from '../../model/app/actions';

import Dialog from '../Dialog';
import PersonSearch from '../PersonSearch';
import {
  PERSON_ERROR_LENGTH,
  PERSON_ERROR_NOT_FOUND,
} from '../../utils/constants';
import useTransientState from '../../utils/useTransientState';

const NAME = 'personMutate';

function getInputErrors(personObject) {
  const { label, error } = personObject;
  if (label?.length < 7) {
    return [PERSON_ERROR_LENGTH];
  }
  if (error) {
    return [PERSON_ERROR_NOT_FOUND];
  }
  return [];
}

let abortController = new AbortController();

function PersonMutateDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const filters = useSelector((state) => state.app.filters);
  const topic = useSelector((state) => state.app.topic);
  const [personOld, setPersonOld] = useState({});
  const [personNew, setPersonNew] = useState({});
  const [loading, setLoading] = useState(false);
  const [applyEnabled, setApplyEnabled] = useState(false);
  const [message, setMessage] = useTransientState(20000);

  useEffect(() => {
    setApplyEnabled(personOld?.uNummer && personNew?.uNummer);
  }, [personOld, personNew]);

  return (
    <Dialog
      title={<b>{t('Person mutieren')}</b>}
      body={
        <>
          <div
            style={{
              display: 'grid',
              gap: 5,
              gridTemplateColumns: '1fr 80px 1fr',
              alignItems: 'center',
              justifyContent: 'center',
              height: 100,
            }}
          >
            <div style={{ height: '100%' }}>
              <PersonSearch
                isRequired
                label={t('Person alt')}
                onChange={(val) => {
                  setPersonOld(val);
                  setMessage(null);
                }}
                inputValue={personOld?.label}
                errors={getInputErrors(personOld)}
                onClear={() => setPersonOld({})}
                fullWidth
              />
            </div>
            <IconButton
              sx={{ width: 50, height: 50, m: 'auto' }}
              onClick={() => {
                setPersonOld({ ...personNew });
                setPersonNew({ ...personOld });
              }}
            >
              <SyncAltIcon fontSize="large" focusable={false} />
            </IconButton>
            <div style={{ height: '100%' }}>
              <PersonSearch
                isRequired
                label={t('Person neu')}
                onChange={(val) => {
                  setPersonNew(val);
                  setMessage(null);
                }}
                inputValue={personNew?.label}
                errors={getInputErrors(personNew)}
                onClear={() => setPersonNew({})}
                fullWidth
              />
            </div>
          </div>
          {message && (
            <Alert
              sx={{
                marginTop: 2,
                fontSize: 14,
                alignItems: 'center',
              }}
              severity={message.severity}
            >
              {message.text}
            </Alert>
          )}
        </>
      }
      footer={
        <>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              const url = `${backendApiUrl}/${topic.key}/search_and_replace_person/?old_sbb_id=${personOld.uNummer}&new_sbb_id=${personNew.uNummer}`;
              abortController.abort();
              abortController = new AbortController();
              setLoading(true);
              setMessage(null);
              fetch(url, {
                credentials: 'include',
                signal: abortController.signal,
              })
                .then((res) => {
                  if (res.status === 200) {
                    return res.json();
                  }
                  throw new Error(res.statusText);
                })
                .then((data) => {
                  let newMessage = {
                    severity: 'info',
                    text: t(
                      'Kein Datensatz mit dieser Person gefunden. Keine Person wurde ersetzt.',
                    ),
                  };
                  if (data?.replaced_people?.length) {
                    dispatch(getFeatures(topic, filters));
                    newMessage = {
                      severity: 'success',
                      text: t('Die Person wurde erfolgreich ersetzt.'),
                    };
                  }
                  setMessage(newMessage);
                  setLoading(false);
                  setApplyEnabled(false);
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                  setMessage({
                    severity: 'error',
                    text: t('Fehler beim Ersetzen der Person.'),
                  });
                  setLoading(false);
                  setApplyEnabled(false);
                });
            }}
            disabled={loading || !applyEnabled}
          >
            {loading ? (
              <>
                <CircularProgress size={15} sx={{ mr: 1, color: 'inherit' }} />
                {t('Vorgang läuft...')}
              </>
            ) : (
              t('Anwenden')
            )}
          </Button>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              dispatch(setDialogVisible());
            }}
          >
            {t('Schliessen')}
          </Button>
        </>
      }
      PaperProps={{
        sx: { maxWidth: 'unset', width: 800 },
      }}
    />
  );
}

const memoized = React.memo(PersonMutateDialog);
memoized.NAME = NAME;
export default memoized;
